<template>
    
  <div class="member-layout">
    <div class="page-title2">
      이미 가입된 회원정보가 존재합니다.<br>카카오 계정 연동 후 서비스를 이용해주세요.
    </div>

    <div class="label-block">
      <div v-for="(item, index) in memberList" :key="`memberList-${index}`">
        <label class="fs-15"><input type="radio" v-model="memIdx" name="email" :value="item.memIdx"><span class="input-icon"></span><span>{{item.email}}</span></label>
      </div>
    </div>

    <div class="add-info-round-box mt-30">이미 가입된 내 이메일 계정의 비밀번호를 입력하면 카카오 계정과 자동으로 연결됩니다.</div>

    <div class="input-title">비밀번호<span class="color-1">*</span></div>
    <input-field type="password" pattern="" v-model="password" placeholder="비밀번호 입력" maxLength="20"/>
    <div class="find-pw-text">비밀번호를 잊으셨나요? <router-link to="/find">비밀번호 찾기</router-link></div>

    <div class="double-button-set2">
      <button @click="newJoin">신규가입</button>
      <button :disabled="!password" @click="connect">연동하기</button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"

export default {
  data() {
    return {
      memIdx: null,
      memberList: [],
      snsId: null,

      password: null,
    }
  },
  beforeDestroy() {
    localStorage.removeItem('kakaosync-connect-data')
  },
  created() {
    const initData = localStorage.getItem('kakaosync-connect-data')
      ? JSON.parse(localStorage.getItem('kakaosync-connect-data'))
      : null
    if (!initData) location.href = '/'
    this.memberList = initData.memberList
    this.snsId = initData.snsId

    this.memIdx = this.memberList[0].memIdx
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async newJoin(){
      const { result, data } = await this.$api.joinNewSns({
        sns_id: this.snsId,
        device: 'M',
      })
      if (result === 'success') {
        if (data.input.length > 0) {
          for (let src of data.input) {
            let script = document.createElement('script')
            script.setAttribute('src', src)
            document.head.appendChild(script)
          }
        }
        setTimeout(() => {
          if (data.script.length > 0) {
            let script = document.createElement('script')
            script.innerHTML = data.script
            document.head.appendChild(script)
          }
          const token = data.token
          const info = this.$parseJwt(token)
          if (info) {
            localStorage.setItem('auth', token)
            localStorage.setItem('id', info.id)
            localStorage.setItem('email', info.email)
            localStorage.setItem('name', info.name)
            localStorage.setItem('url', info.url ? info.url : '')
            localStorage.setItem('target', info.target)
            localStorage.setItem('phone', info.phone)
          }
          location.href = "/join/complete"
        }, 300)
      }
      else {
        this.$msg('fail')
      }
    },
    async connect() {
      const memIdx = this.memIdx
      const email = this.memberList.find(m => m.memIdx === this.memIdx).email
      const password = this.password
      const snsId = this.snsId

      const { result } = await this.$api.checkForKakaoSyncConnect({
        memIdx: memIdx,
        email: email,
        password: password,
      })

      switch (result) {
        case 'success':
          this.setMsgPopup({
            type: 'confirm',
            message: this.$msg('confirm.kakaosync-connect'),
            okay: 'KAKAOSYNC_CONNECT',
            okayCallback: async () => {
              this.setMsgPopup()
              const { result, data } = await this.$api.connectKakaoSync({
                sns_id: snsId,
                memIdx: memIdx,
                email: email,
                password: password,
              })
              if (result === 'success') {
                const info = this.$parseJwt(data.token)
                if (info) {
                  localStorage.setItem('auth', data.token)
                  localStorage.setItem('id', info.id)
                  localStorage.setItem('email', info.email)
                  localStorage.setItem('name', info.name)
                  localStorage.setItem('url', info.url ? info.url : '')
                  localStorage.setItem('target', info.target)
                  localStorage.setItem('phone', info.phone)
                }
                location.href = "/"
              }
              else if (result === 'sleep') {
                localStorage.setItem('sleep', data.token)
                location.href = "/sleep"
              }
              else {
                this.$msg('fail')
              }
            },
            cancel: 'CLOSE',
            cancelCallback: () => this.setMsgPopup()
          })
          break;
        case 'password-wrong':
          this.alertPopup(this.$msg('password.no-match'))
          break;
        case 'no-exist':
          this.alertPopup(this.$msg('email.no-exist2'))
          break;
        default:
          this.alertPopup(this.$msg('fail'))
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  },
}
</script>